import { MatxLoadable } from "matx";

const Index = MatxLoadable({
  loader: () => import("./banner"),
});

const Routes = [
  {
    path: "/banner",
    component: Index,
    exact: true,
  },
];

export default Routes;
