import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Dashboard = MatxLoadable({
  loader: () => import("./Dashboard")
})

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard
  }
];

export default dashboardRoutes;
