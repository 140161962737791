import axios from 'axios';
import localStorageService from './localStorageService';
import {api_url} from '../../config'

class JwtAuthService {

	// You need to send http request with email and passsword to your server in this method
	// Your server will return user object & a Token
	// User should have role property
	// You can define roles in app/auth/authRoles.js
	loginWithUsernameAndPassword = async (username, password) => {
		let log = await axios.post(`${api_url}/api/auth/login`, {username, password})
		log = log.data.data
		this.setSession(log.token);
		sessionStorage.setItem('token', log.token);
		this.setUser(log);
		return log
	};

	// You need to send http requst with existing token to your server to check token is valid
	// This method is being used when user logged in & app is reloaded
	loginWithToken = () => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(this.user);
			}, 100);
		}).then((data) => {
			// Token is valid
			this.setSession(data.token);
			this.setUser(data);
			return data;
		});
	};

	logout = () => {
		this.setSession(null);
		this.removeUser();
	};

	// Set token to all http request header, so you don't need to attach everytime
	setSession = (token) => {
		if (token) {
			localStorage.setItem('jwt_token', token);
			axios.defaults.headers['Authorization'] = token;
		} else {
			localStorage.removeItem('jwt_token');
			sessionStorage.clear();
			delete axios.defaults.headers['Authorization'];
		}
	};

	// Save user to localstorage
	setUser = (user) => {
		localStorageService.setItem('user_menu', user.menu);
		localStorageService.setItem('auth_user', user);
	};
	// Remove user from localstorage
	removeUser = () => {
		localStorage.removeItem('auth_user');
		localStorage.removeItem('user_menu');
	};
}

export default new JwtAuthService();
