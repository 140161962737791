import { MatxLoadable } from "matx";

const Index = MatxLoadable({
  loader: () => import("./inputTransaksi")
})

const Routes = [
  {
    path: "/input-transaksi",
    component: Index
  }
];

export default Routes;
