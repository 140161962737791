import React from 'react';
import { Redirect } from 'react-router-dom';
import dashboardRoutes from './views/dashboard/DashboardRoutes';
import fotoRoutes from './views/foto-outlet/fotoOutletRoutes';
import insentifRoutes from './views/insentif/routes';
import inputTransaksiRoutes from './views/input-transaksi/inputTransaksiRoutes';
import listTransferRoutes from './views/list-transfer/listTransferRoutes';
import jenisOutletRoutes from './views/jenis-outlet/jenisOutletRoute';
import bannerRoutes from './views/banner/bannerRoute';
import userScopeRoutes from './views/user-scope/routes';
import sessionRoutes from './views/sessions/SessionRoutes';

const redirectRoute = [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to='/dashboard' />,
	},
];

const errorRoute = [
	{
		component: () => <Redirect to='/session/404' />,
	},
];

const routes = [
	...sessionRoutes,
	...dashboardRoutes,
	...fotoRoutes,
	...bannerRoutes,
	...insentifRoutes,
	...userScopeRoutes,
	...listTransferRoutes,
	...jenisOutletRoutes,
	...inputTransaksiRoutes,
	...redirectRoute,
	...errorRoute,
];

export default routes;
