import "../styles/_app.scss";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import MatxTheme from "./MatxLayout/MatxTheme/MatxTheme";
import AppContext from "./appContext";
import history from "history.js";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import Auth from "./auth/Auth";
import MatxLayout from "./MatxLayout/MatxLayout";
import AuthGuard from "./auth/AuthGuard";

// window.onbeforeunload = function (e) {
//   window.onunload = function () {
//     localStorage.removeItem("jwt_token");
//     localStorage.removeItem("auth_user");
//   };
//   return undefined;
// };

// window.onload = function () {
//   window.localStorage.isMySessionActive = "true";
// };

const App = (props) => {
  React.useState(() => {
    if (!sessionStorage.getItem("token")) {
      sessionStorage.clear()
      // props.history.push("/session/signin");
      history.push({
        pathname: "/session/signin"
      });
      return localStorage.clear();
    }
  }, []);
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <MatxTheme>
          <Auth>
            <Router history={history}>
              <AuthGuard>
                <MatxLayout />
              </AuthGuard>
            </Router>
          </Auth>
        </MatxTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
