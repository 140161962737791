import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";

const AlertMsg = (props) => {
  const type = props.type || "success";
  if (type === "error") {
    return (
      <div>
        <Alert severity="error" {...props}>
          <AlertTitle>Oopss</AlertTitle>
          {props.message || <strong>check it out!</strong>}
        </Alert>
      </div>
    );
  } else {
    return (
      <div>
        <Alert severity="success" {...props}>
          <AlertTitle>Success</AlertTitle>
          {props.message || <strong>check it out!</strong>}
        </Alert>
      </div>
    );
  }
};

export default AlertMsg;
