import jwtAuthService from '../../services/jwtAuthService';
import { setUserData } from './UserActions';
import history from 'history.js';
import instance from 'app/services/request';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export function loginWithUsernameAndPassword({ username, password }) {
	return (dispatch) => {
		dispatch({
			type: LOGIN_LOADING, 
		});

		jwtAuthService
			.loginWithUsernameAndPassword(username, password)
			.then((user) => {
				dispatch(setUserData(user));
				instance.defaults.headers.common['Authorization'] = user.token
				
				history.push({
					pathname: user.level === 8 ? '/foto' : '/',
				});

				return dispatch({
					type: LOGIN_SUCCESS,
				});
			})
			.catch((error) => {
				return dispatch({
					type: LOGIN_ERROR,
					payload: error.response.data.data,
				});
			});
	};
}

export function resetPassword({ email }) {
	return (dispatch) => {
		dispatch({
			payload: email,
			type: RESET_PASSWORD,
		});
	};
}
