import { MatxLoadable } from "matx";

const Index = MatxLoadable({
  loader: () => import("./listTransfer"),
});
const Detail = MatxLoadable({
  loader: () => import("./listTransferDetail"),
});

const Routes = [
  {
    path: "/list-transfer",
    component: Index,
    exact: true,
  },
  {
    path: "/list-transfer/detail/:id/:bulan",
    component: Detail,
    exact: true,
  },
];

export default Routes;
