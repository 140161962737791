import { MatxLoadable } from "matx";

const Index = MatxLoadable({
  loader: () => import("./UserScope")
})

const Routes = [
  {
    path: "/user-scope",
    component: Index
  }
];

export default Routes;
